.portfolio__container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}
.portfolio__item{
    background: var(--color-bg-variant);
    padding: 1.3rem;
 
    border-radius: 2rem;
    border:  1px solid transparent;
    transition: var(--transation);   
}

.portfolio__item h3{
    padding-top: 2rem;
}

.portfolio_item :hover{
    background: transparent;
    border-color: var(--color-bg-variant);
}

.portfolio__item-cta{
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

/* ======================= Medıa Querıes (medium devices) ======================= */

@media screen and (max-width:1024px) {
    .portfolio__container{
      
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
  }
  
  /* ======================= Medıa Querıes (Small devices) ======================= */
  
  @media screen and (max-width:600px) {
    .portfolio__container{
      
        grid-template-columns: 1fr ;
        gap: 1rem;
    }
       
  }